import React from "react";
import "@fontsource/inter/100.css";
import "@fontsource/lexend/200.css";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import CtaSection from "./../components/CtaSection";
import {PrimaryFeatures} from "../components/PrimaryFeatures";
import {SecondaryFeatures} from "../components/SecondaryFeatures";
import {CallToAction} from "../components/CallToAction";
import FaqSection from "../components/FaqSection";
import {ValueToCustomer} from "../components/ValueToCustomer";

function IndexPage() {
  return (
      <div className='font-main'>
          <Meta/>

          <HeroSection2
              title={
                  null
              }
              subtitle="Stay in the flow, without"
              strapline=""
              size="md"
              bgColor="bg-white"
              bgImage=""
              bgImageOpacity={1}
              textColor=""
          />

          <PrimaryFeatures/>
          <SecondaryFeatures/>
          <CallToAction/>
          {/*<FeaturesSection*/}
          {/*    */}
          {/*    title="Take a quick look"*/}
          {/*    subtitle={(<>*/}
          {/*            We save you time to shorten your workday*/}
          {/*        <Button*/}
          {/*            component={Link}*/}
          {/*            to="/get-started"*/}
          {/*            size="md"*/}
          {/*            className={'text-center content-center'}*/}
          {/*            style={{margin: '30px auto', width: '300px', display: 'block'}}*/}
          {/*            endIcon={*/}
          {/*                <ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />*/}
          {/*            }*/}
          {/*        >*/}
          {/*            Get Started*/}
          {/*        </Button>*/}
          {/*    </>)}*/}
          {/*    strapline="Features"*/}
          {/*    size="md"*/}
          {/*    bgColor="bg-white"*/}
          {/*    bgImage=""*/}
          {/*    bgImageOpacity={1}*/}
          {/*    textColor=""*/}
          {/*/>*/}

          {/*<TestimonialsSection*/}
          {/*    title=""*/}
          {/*    subtitle=""*/}
          {/*    strapline=""*/}
          {/*    size="md"*/}
          {/*    bgColor="bg-white"*/}
          {/*    bgImage=""*/}
          {/*    bgImageOpacity={1}*/}
          {/*    textColor=""*/}
          {/*    id='testimonials'*/}
          {/*/>*/}
          <ValueToCustomer />
          <FaqSection
              title="Frequently Asked Questions"
              subtitle=""
              strapline=""
              size="md"
              bgColor="bg-white"
              bgImage=""
              bgImageOpacity={1}
              textColor=""
              showSupportButton={false}
              supportUrl="https://zendesk.com"
          />

          <CtaSection
              title={
                  <>
                      Create a free account <span className="text-blue-600">today</span>!
                  </>
              }
              subtitle=""
              strapline=""
              size="md"
              bgColor="bg-white"
              bgImage=""
              bgImageOpacity={1}
              textColor=""
          />

      </div>
  );
}

export default IndexPage;
