import React from "react";
import Meta from "./../../components/Meta";
import {MailChimpSignUp} from "../../components/MailChimpSignUp";
import Landing from './app/page'
import {StripeCheckoutForm} from "./stripe-checkout-form-component";


function FaqPage() {
  return (
    <>
      <Meta title="Working Mother's Career Jumpstart: Resume" />

        <MailChimpSignUp/>
        <StripeCheckoutForm/>
        <Landing/>
    </>
  );
}

export default FaqPage;
