import Analytics from "analytics";
import googleTagManager from '@analytics/google-tag-manager'
import {history} from "./router.js";

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = Analytics({
  debug: process.env.NODE_ENV !== "production",
  plugins: [
    googleTagManager({
      containerId: 'GTM-TFDR8ZT5'
    })
  ],
});

// Track initial pageview
if (typeof window !== "undefined") {
  analytics.page();
}

// Track pageview on route change
history.listen(() => {
  analytics.page();
});

export const analyticsTrackingIds = {
  signUpFormId: 'signup',
  signUpSuccessConfirmation: 'signup-success',
  signUpPasswordField: 'pass',
  signUpEmailField: 'email',
  signUpConfirmPasswordField: 'confirmPass',
  signUpNameField: 'name',
  signUpSubmitButton: 'signup-submit',
}

export default analytics;
