import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function FaqSection(props) {
  const faqItemsCol1 = [
    {
      question: "Can I just do this myself? Hasn't Google thought of a solution?",
      answer: (
          <>
            Well yes, ... kind of, in some ways, but mostly no. People like ourselves with many Google accounts know that there are ways to "get it working"... some painful.
            <br/>In short, there have been attempts but the ability to go across multiple accounts, and search into documents, and Google drive
            with any account, does not exist, with Google or any other app / extension.
          </>
      )
    },
    {
      question: "Can I get a refund?",
      answer: (<>
        We work for 5 star reviews. If we didn't hit the mark, reach out. We will make it right, fix the issue, and give you your money back.
      </>),
    },
  ];

  const faqItemsCol2 = [
      {
        question: "Can you really not see / use my data?",
        answer: (<>
          Absolutely. Your family and work data stays private, stored locally on your device, fully encrypted for your peace of mind.
        </>)
      },
      {
      question: "Are the updates free for life?",
      answer:
          (<>Totally and Completely</>)
    },
    {
      question: "How do I get support",
      answer: (<>
        Great Question. There is a chat in the bottom right of this website. (blue button with white chat icon inside).
        That is the best. You can also email the founder, Dominic, directly at dominic@allmythings.co. [Hi, I'm Dominic. I really want to hear from you and earn
        that 5 star review. I really do.]</>)
    },
  ];

  const faqItemsCol3 = [
    {
      question: "A Chrome Extension that cost money?",
      answer: (<>
        Yeah. It makes our relationship simple, we save you massive time and headache in your professional life and you pay us a small fee.
        We take some of the money and buy food. With the rest we build more features to save you more time and headache.
        The cycle continues. We become friends, maybe take a vacation together. Who knows, we could officiate your wedding someday.
      </>)
    },
    {
      question: "What are your plans for the future?",
      answer: (<>
        Having many conversations with our awesome customers. Building specific flows for specific job titles, to save people more time.
        Some very smart integrations are coming up. Making technology work for those who are working hard. Better and better,
        day by day, release by release.
      </>)
    },

  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-4">
            {faqItemsCol1.map((item, index) => (
              <div className="bg-gray-100 rounded p-5" key={index}>
                <h4 className="font-semibold mb-2">{item.question}</h4>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
          <div className="space-y-4">
            {faqItemsCol2.map((item, index) => (
              <div className="bg-gray-100 rounded p-5" key={index}>
                <h4 className="font-semibold mb-2">{item.question}</h4>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
          <div className="space-y-4">
            {faqItemsCol3.map((item, index) => (
              <div className="bg-gray-100 rounded p-5" key={index}>
                <h4 className="font-semibold mb-2">{item.question}</h4>
                <p className="text-sm text-gray-600 leading-relaxed">
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
        </div>

        {props.showSupportButton && (
          <div className="text-center">
            <Button
              href={props.supportUrl}
              target="_blank"
              size="lg"
              variant="simple"
              startIcon={
                <ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5" />
              }
            >
              Go to support center
            </Button>
          </div>
        )}
      </div>
    </Section>
  );
}

export default FaqSection;
