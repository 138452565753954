import React, {useEffect} from "react";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import {loginExtensionWithToken, requireAuth} from "../util/auth";
import {openExtension} from "../util/extension";

function ExtensionPage() {

    useEffect(() => {
        loginExtensionWithToken()
            .then(() => openExtension())
    }, []);

  return (
    <>
      <Meta title="Signing into All My Things Chrome Extension..." />
      <PageLoader></PageLoader>
    </>
  );
}

export default requireAuth(ExtensionPage);
