import React from "react";
import { BookmarkIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";
import { useAuth } from "../util/auth";
import {LockClosedIcon} from "@heroicons/react/24/solid";
import gmailIcon from './icons/gmailIcon.svg'
import googleCalendarIcon from './icons/googleCalendarIcon.svg'
import googleDocsIcon from './icons/googleDocsLogo.svg'
import googleDriveIcon from './icons/googleDriveIcon.svg'
import {AsanaIcon, JiraIcon, NotionIcon} from './ClientsSection'

function PricingSection(props) {
  const auth = useAuth();

  const plans = [
    {
      id: "basic",
      name: "Basic",
      price: "0",
      per: '',
      free: true,
      subtitle: "Search across all aspects of your Google Account",
      perks: [
        <>
          Search <strong>2</strong> Google Accounts all at once!
        </>,
        <>
          Save massive time finding information.
        </>,
        <>
          <span className='text'>Gmail</span>
        </>,
        <>
           <span className='text'>Google Calendar</span>
        </>,
        <>
           <span className='text'>Google Docs</span>
        </>,
        <>
           <span className='text'>Google Drive</span>
        </>,
      ],
    },
    {
      id: "monthly",
      name: "Monthly",
      price: "9",
      per: 'mon',
      subtitle: "Quickly navigate all your business and personal accounts at once!",
      featured: true,
      className: 'z-20',
      perks: [
          <>
            Everything in Basic
          </>,
        <>
          Up to <strong>10</strong> Google Accounts Integrations
        </>,
        <>
          <strong>No</strong> logging out and logging in
        </>,
        <>
          Full Text Search across <strong>all</strong> accounts at once
        </>,
        <>
          <img src={gmailIcon} alt='Gmail Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Gmail : Email Body Search</span>
        </>,
        <>
          <img src={googleCalendarIcon} alt='Google Calendar Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Google Calendar : Event Description Search</span>
        </>,
        <>
          <img src={googleDocsIcon} alt='Google Docs Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Google Docs : Full Document Search</span>
        </>,
        <>
          <img src={googleDriveIcon} alt='Google Drive Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Google Drive : All Item Types</span>
        </>,
      ],
    },
    {
      id: "yearly",
      name: "Yearly",
      price: "95",
      featured: false,
      per: 'year',
      className: 'z-10',
      subtitle: "Quickly navigate all your business and personal accounts at once!",
      perks: [
        <>
          Everything in Basic
        </>,
        <>
          Up to <strong>10</strong> Google Accounts Integrations
        </>,
        <>
          <strong>No</strong> logging out and logging in
        </>,
        <>
          Full Text Search across <strong>all</strong> accounts at once
        </>,
        <>
          <img src={gmailIcon} alt='Gmail Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Gmail : Email Body Search</span>
        </>,
        <>
          <img src={googleCalendarIcon} alt='Google Calendar Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Google Calendar : Event Description Search</span>
        </>,
        <>
          <img src={googleDocsIcon} alt='Google Docs Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Google Docs : Full Document Search</span>
        </>,
        <>
          <img src={googleDriveIcon} alt='Google Drive Icon' className='w-6 h-6 inline'/> <span className='text-sm'>Google Drive : All Item Types</span>
        </>,
        <>
          <JiraIcon className='w-4 inline '/> <AsanaIcon className='w-4 inline' /> <NotionIcon className='w-4 inline'/> Price lock guarantee.
        </>,
      ],
    }
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-10 container">
        <SectionHeader
            title='Simplify. Even More.'
            subtitle='Save seconds of frustration every minute. Gain hours of productivity every week.'
            strapline='You will see the value fast.'
            className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-0 lg:py-6">
          {plans.map((plan, index) => (
              <div
                  className={
                      "rounded-lg shadow-sm flex flex-col border-2 " + plan.className + ' ' +
                      (plan.featured
                          ? " bg-blue-50 lg:border-4 border-blue-300 hover:border-blue-400 relative lg:-mx-2 lg:-my-6"
                          : "") +
                      (!plan.featured
                          ? " bg-gray-100 border-gray-200 hover:border-gray-300"
                  : "")
              }
              key={index}
            >
              {plan.featured && (
                <div className="absolute top-0 right-0 h-10 w-10 flex items-center justify-center">
                  <BookmarkIcon className="inline-block w-6 h-6 text-orange-400" />
                </div>
              )}

              <div className="p-5 lg:p-6 text-center bg-white rounded-t-lg">
                <span className="inline-block text-sm uppercase tracking-wider font-semibold px-3 py-1 bg-blue-200 bg-opacity-50 text-blue-600 rounded-full mb-4">
                  {plan.name}
                </span>
                <div className="mb-1">
                  <span className="text-3xl lg:text-4xl font-extrabold">
                    ${plan.price}
                  </span>
                  { plan.per && <span className="text-gray-700 font-semibold">/{plan.per}</span> }
                  <p className="text-gray-600 text-sm font-medium">
                    {plan.subtitle}
                  </p>
                </div>
              </div>

              {plan.perks && (
                <div
                  className={
                    "p-5 lg:p-6 space-y-5 lg:space-y-6 text-gray-700 grow" +
                    (plan.featured ? " text-blue-900" : "")
                  }
                >
                  <ul className="space-y-4 text-sm lg:text-base">
                    {plan.perks.map((perk, index) => (
                      <li className="flex items-center space-x-2" key={index}>
                        <CheckCircleIcon className="text-emerald-500 inline-block w-5 h-5" />
                        <span>{perk}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="px-5 pb-5 lg:px-6 lg:pb-6">
                { !plan.free && (!auth.user || !auth.user.planIsActive || auth.user.planId !== plan.id) &&
                    <Button
                      component={Link}
                      to={
                        auth.user
                          ? `/purchase/${plan.id}`
                          : `/auth/signup?next=/purchase/${plan.id}`
                      }
                      size="lg"
                      variant={plan.featured ? "primary" : "dark"}
                      isBlock={true}
                      id={`${plan.id}-subscription-signup-btn`}
                    >
                      Go Pro
                    </Button>
                }
                { !plan.free && auth.user && auth.user.planIsActive && auth.user.planId === plan.id &&
                    <Button
                        size="lg"
                        variant={plan.featured ? "primary" : "dark"}
                        isBlock={true}
                    >
                      Current Plan
                    </Button>
                }
                { plan.free && !auth.user &&
                    <Button
                        component={Link}
                        to={`/get-started`}
                        size="lg"
                        variant={plan.featured ? "primary" : "dark"}
                        isBlock={true}
                        id='get-started-btn-pricing-page-free-account'
                    >
                      Get Started
                    </Button>
                }
                { plan.free && auth.user && !auth.user.planIsActive &&
                    <Button
                        size="lg"
                        variant={plan.featured ? "primary" : "dark"}
                        isBlock={true}
                    >
                      Current Plan
                    </Button>
                }
                { plan.free && auth.user && auth.user.planIsActive &&
                    <Button
                        component={Link}
                        to={`/settings/billing`}
                        size="lg"
                        variant={plan.featured ? "primary" : "dark"}
                        isBlock={true}
                    >
                      Go Basic
                    </Button>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default PricingSection;
