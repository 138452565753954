import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

export function AsanaIcon (params) {
  return <svg {...params} viewBox="0 0 97 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M75.7325 47.3578C64.107 47.3578 54.6843 56.7809 54.6843 68.4053C54.6843 80.0305 64.107 89.4543 75.7325 89.4543C87.3566 89.4543 96.78 80.0305 96.78 68.4053C96.78 56.7809 87.3566 47.3578 75.7325 47.3578ZM21.0479 47.3593C9.42379 47.3593 0 56.7809 0 68.4064C0 80.0305 9.42379 89.4543 21.0479 89.4543C32.6727 89.4543 42.0968 80.0305 42.0968 68.4064C42.0968 56.7809 32.6727 47.3593 21.0479 47.3593ZM69.4373 21.0475C69.4373 32.672 60.0147 42.0972 48.3906 42.0972C36.7654 42.0972 27.3423 32.672 27.3423 21.0475C27.3423 9.4238 36.7654 0 48.3906 0C60.0147 0 69.4373 9.4238 69.4373 21.0475Z"
        fill="url(#paint0_radial)"/>
    <defs>
      <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(48.39 48.8887) scale(64.1297 59.2754)">
        <stop stopColor="#FFB900"/>
        <stop offset="0.6" stopColor="#F95D8F"/>
        <stop offset="0.9991" stopColor="#F95353"/>
      </radialGradient>
    </defs>
  </svg>
}

export function JiraIcon(params) {
  return <svg {...params} viewBox="0 0 74 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M72.3998 35.76L39.7998 3.16L36.6398 0L12.0998 24.54L0.879802 35.76C0.318 36.3225 0.00244141 37.085 0.00244141 37.88C0.00244141 38.675 0.318 39.4375 0.879802 40L23.2998 62.42L36.6398 75.76L61.1798 51.22L61.5598 50.84L72.3998 40C72.9616 39.4375 73.2772 38.675 73.2772 37.88C73.2772 37.085 72.9616 36.3225 72.3998 35.76V35.76ZM36.6398 49.08L25.4398 37.88L36.6398 26.68L47.8398 37.88L36.6398 49.08Z"
        fill="#2684FF"/>
    <path
        d="M36.6398 26.6801C33.1173 23.1571 31.1317 18.3835 31.1167 13.4016C31.1017 8.41967 33.0585 3.63419 36.5598 0.0900879L12.0498 24.5901L25.3898 37.9301L36.6398 26.6801Z"
        fill="url(#paint0_linear)"/>
    <path
        d="M47.8696 37.8501L36.6396 49.0801C38.3921 50.8316 39.7824 52.9112 40.7309 55.2001C41.6794 57.489 42.1676 59.9424 42.1676 62.4201C42.1676 64.8978 41.6794 67.3512 40.7309 69.6401C39.7824 71.929 38.3921 74.0086 36.6396 75.7601V75.7601L61.2096 51.1901L47.8696 37.8501Z"
        fill="url(#paint1_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="34.6398" y1="15.3501" x2="18.9998" y2="30.9901"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.18" stopColor="#0052CC"/>
        <stop offset="1" stopColor="#2684FF"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="989.464" y1="2323.07" x2="1529.64" y2="1972.97"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.18" stopColor="#0052CC"/>
        <stop offset="1" stopColor="#2684FF"/>
      </linearGradient>
    </defs>
  </svg>
}

export function NotionIcon(params) {
  return <svg xmlns="http://www.w3.org/2000/svg" {...params} viewBox="0 0 400 400" fill="none">
    <path
        d="M33.9473 18.1239L254.183 1.90564C281.227 -0.413975 288.186 1.13982 305.184 13.4879L375.486 62.899C387.086 71.3959 390.952 73.7092 390.952 82.9717V353.975C390.952 370.959 384.765 381.003 363.133 382.54L107.376 397.984C91.1378 398.759 83.4095 396.444 74.9057 385.629L23.1344 318.458C13.8577 306.095 10 296.844 10 286.022V45.1365C10 31.2473 16.1887 19.6619 33.9473 18.1239Z"
        fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M254.183 1.90564L33.9473 18.1239C16.1887 19.6619 10 31.2473 10 45.1365V286.022C10 296.844 13.8577 306.095 23.1344 318.458L74.9057 385.629C83.4095 396.444 91.1378 398.759 107.376 397.984L363.133 382.54C384.765 381.003 390.952 370.959 390.952 353.975V82.9717C390.952 74.1922 387.478 71.656 377.238 64.1797C376.674 63.7684 376.091 63.3421 375.486 62.899L305.184 13.4879C288.186 1.13982 281.227 -0.413975 254.183 1.90564ZM113.175 78.5266C92.2938 79.9366 87.5491 80.257 75.6911 70.6238L45.5362 46.6901C42.4606 43.5952 44.0032 39.7345 51.7248 38.9688L263.456 23.5258C281.224 21.9783 290.495 28.165 297.453 33.5669L333.768 59.8199C335.314 60.5888 339.171 65.2155 334.533 65.2155L115.87 78.3453C114.95 78.4068 114.058 78.467 113.194 78.5253L113.178 78.5264L113.175 78.5266ZM88.8193 351.654V121.583C88.8193 111.548 91.9105 106.912 101.178 106.133L352.305 91.4685C360.822 90.6965 364.679 96.1047 364.679 106.133V334.66C364.679 344.705 363.13 353.21 349.216 353.975L108.906 367.879C94.9984 368.644 88.8193 364.019 88.8193 351.654ZM326.053 133.924C327.594 140.88 326.053 147.829 319.085 148.611L307.506 150.918V320.771C297.453 326.175 288.183 329.263 280.458 329.263C268.09 329.263 264.992 325.4 255.728 313.825L179.991 194.927V309.965L203.957 315.373C203.957 315.373 203.957 329.263 184.621 329.263L131.317 332.356C129.768 329.263 131.317 321.549 136.724 320.003L150.634 316.148V164.048L131.32 162.5C129.771 155.544 133.629 145.516 144.455 144.737L201.638 140.883L280.458 261.329V154.778L260.362 152.472C258.819 143.969 264.992 137.794 272.72 137.029L326.053 133.924Z"
          fill="black"/>
  </svg>
}

function GoogleIcon (params) {
  return <>
    <svg {...params} xmlns="http://www.w3.org/2000/svg" viewBox="52 42 88 66">
      <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6"/>
      <path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15"/>
      <path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2"/>
      <path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92"/>
      <path fill="#c5221f" d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"/>
    </svg>

    <svg {...params} xmlns="http://www.w3.org/2000/svg" viewBox="186 38 76 76">
      <path fill="#fff" d="M244 56h-40v40h40V56z"/>
      <path fill="#EA4335" d="M244 114l18-18h-18v18z"/>
      <path fill="#FBBC04" d="M262 56h-18v40h18V56z"/>
      <path fill="#34A853" d="M244 96h-40v18h40V96z"/>
      <path fill="#188038" d="M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z"/>
      <path fill="#1967D2" d="M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z"/>
      <path fill="#4285F4" d="M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z"/>
      <path fill="#4285F4"
            d="M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z"/>
    </svg>

    <svg {...params} viewBox="0 0 87.3 78" xmlns="http://www.w3.org/2000/svg">
      <path d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z" fill="#0066da"/>
      <path d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z" fill="#00ac47"/>
      <path d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z"
            fill="#ea4335"/>
      <path d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z" fill="#00832d"/>
      <path d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z" fill="#2684fc"/>
      <path d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z"
            fill="#ffba00"/>
    </svg>
  </>
}

function SlackIcon(params) {
  return <svg {...params} viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg" role="presentation">
    <title>Slack</title>
    <g fill="none" fill-rule="evenodd">
      <path
          d="M19.712.133a5.381 5.381 0 0 0-5.376 5.387 5.381 5.381 0 0 0 5.376 5.386h5.376V5.52A5.381 5.381 0 0 0 19.712.133m0 14.365H5.376A5.381 5.381 0 0 0 0 19.884a5.381 5.381 0 0 0 5.376 5.387h14.336a5.381 5.381 0 0 0 5.376-5.387 5.381 5.381 0 0 0-5.376-5.386"
          fill="#44BEDF"></path>
      <path
          d="M53.76 19.884a5.381 5.381 0 0 0-5.376-5.386 5.381 5.381 0 0 0-5.376 5.386v5.387h5.376a5.381 5.381 0 0 0 5.376-5.387m-14.336 0V5.52A5.381 5.381 0 0 0 34.048.133a5.381 5.381 0 0 0-5.376 5.387v14.364a5.381 5.381 0 0 0 5.376 5.387 5.381 5.381 0 0 0 5.376-5.387"
          fill="#2EB67D"></path>
      <path
          d="M34.048 54a5.381 5.381 0 0 0 5.376-5.387 5.381 5.381 0 0 0-5.376-5.386h-5.376v5.386A5.381 5.381 0 0 0 34.048 54m0-14.365h14.336a5.381 5.381 0 0 0 5.376-5.386 5.381 5.381 0 0 0-5.376-5.387H34.048a5.381 5.381 0 0 0-5.376 5.387 5.381 5.381 0 0 0 5.376 5.386"
          fill="#ECB22E"></path>
      <path
          d="M0 34.249a5.381 5.381 0 0 0 5.376 5.386 5.381 5.381 0 0 0 5.376-5.386v-5.387H5.376A5.381 5.381 0 0 0 0 34.25m14.336-.001v14.364A5.381 5.381 0 0 0 19.712 54a5.381 5.381 0 0 0 5.376-5.387V34.25a5.381 5.381 0 0 0-5.376-5.387 5.381 5.381 0 0 0-5.376 5.387"
          fill="#E01E5A"></path>
    </g>
  </svg>
}

function LinkedIn(params) {
  return <svg xmlns="http://www.w3.org/2000/svg" {...params} viewBox="0 0 72 72" width="72">
    <g fill="none" fill-rule="evenodd">
      <path
          d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z"
          fill="#007EBB"/>
      <path
          d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z"
          fill="#FFF"/>
    </g>
    <script xmlns="" id="bw-fido2-page-script"/>
  </svg>
}

function Microsoft360 (params) {
  return <svg {...params} xmlns="http://www.w3.org/2000/svg" id="image-2" fill="none" viewBox="4 2 40 44">
    <title>Microsoft 365 logo (2022)</title>
    <path
        d="M20.0842 3.02588L19.8595 3.16179C19.5021 3.37799 19.1654 3.61972 18.8512 3.88385L19.4993 3.42798H25L26 11L21 16L16 19.4754V23.4829C16 26.2819 17.4629 28.8774 19.8574 30.3268L25.1211 33.5129L14 40.0002H11.8551L7.85737 37.5804C5.46286 36.131 4 33.5355 4 30.7365V17.2606C4 14.4607 5.46379 11.8645 7.85952 10.4154L19.8595 3.15687C19.9339 3.11189 20.0088 3.06823 20.0842 3.02588Z"
        fill="url(#paint0_radial_2994_8373)"/>
    <path
        d="M20.0842 3.02588L19.8595 3.16179C19.5021 3.37799 19.1654 3.61972 18.8512 3.88385L19.4993 3.42798H25L26 11L21 16L16 19.4754V23.4829C16 26.2819 17.4629 28.8774 19.8574 30.3268L25.1211 33.5129L14 40.0002H11.8551L7.85737 37.5804C5.46286 36.131 4 33.5355 4 30.7365V17.2606C4 14.4607 5.46379 11.8645 7.85952 10.4154L19.8595 3.15687C19.9339 3.11189 20.0088 3.06823 20.0842 3.02588Z"
        fill="url(#paint1_linear_2994_8373)"/>
    <path
        d="M32 19V23.4803C32 26.2793 30.5371 28.8748 28.1426 30.3242L16.1426 37.5878C13.6878 39.0737 10.6335 39.1273 8.1355 37.7487L19.8573 44.844C22.4039 46.3855 25.5959 46.3855 28.1426 44.844L40.1426 37.5803C42.5371 36.1309 43.9999 33.5354 43.9999 30.7364V27.5L42.9999 26L32 19Z"
        fill="url(#paint2_radial_2994_8373)"/>
    <path
        d="M32 19V23.4803C32 26.2793 30.5371 28.8748 28.1426 30.3242L16.1426 37.5878C13.6878 39.0737 10.6335 39.1273 8.1355 37.7487L19.8573 44.844C22.4039 46.3855 25.5959 46.3855 28.1426 44.844L40.1426 37.5803C42.5371 36.1309 43.9999 33.5354 43.9999 30.7364V27.5L42.9999 26L32 19Z"
        fill="url(#paint3_linear_2994_8373)"/>
    <path
        d="M40.1405 10.4153L28.1405 3.15678C25.6738 1.66471 22.6021 1.61849 20.0979 3.01811L19.8595 3.16231C17.4638 4.61143 16 7.20757 16 10.0075V19.4914L19.8595 17.1568C22.4051 15.6171 25.5949 15.6171 28.1405 17.1568L40.1405 24.4153C42.4613 25.8192 43.9076 28.2994 43.9957 30.9985C43.9986 30.9113 44 30.824 44 30.7364V17.2605C44 14.4606 42.5362 11.8644 40.1405 10.4153Z"
        fill="url(#paint4_radial_2994_8373)"/>
    <path
        d="M40.1405 10.4153L28.1405 3.15678C25.6738 1.66471 22.6021 1.61849 20.0979 3.01811L19.8595 3.16231C17.4638 4.61143 16 7.20757 16 10.0075V19.4914L19.8595 17.1568C22.4051 15.6171 25.5949 15.6171 28.1405 17.1568L40.1405 24.4153C42.4613 25.8192 43.9076 28.2994 43.9957 30.9985C43.9986 30.9113 44 30.824 44 30.7364V17.2605C44 14.4606 42.5362 11.8644 40.1405 10.4153Z"
        fill="url(#paint5_linear_2994_8373)"/>
    <path d="M4.00428 30.9984C4.00428 30.9984 4.00428 30.9984 4.00428 30.9984Z" fill="url(#paint6_radial_2994_8373)"/>
    <path d="M4.00428 30.9984C4.00428 30.9984 4.00428 30.9984 4.00428 30.9984Z" fill="url(#paint7_linear_2994_8373)"/>
    <defs>
      <radialGradient id="paint0_radial_2994_8373" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(17.4186 10.6383) rotate(110.528) scale(33.3657 58.1966)">
        <stop offset="0.06441" stop-color="#AE7FE2"/>
        <stop offset="1" stop-color="#0078D4"/>
      </radialGradient>
      <linearGradient id="paint1_linear_2994_8373" x1="17.5119" y1="37.8685" x2="12.7513" y2="29.6347"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#114A8B"/>
        <stop offset="1" stop-color="#0078D4" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint2_radial_2994_8373" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(10.4299 36.3511) rotate(-8.36717) scale(31.0503 20.5108)">
        <stop offset="0.133928" stop-color="#D59DFF"/>
        <stop offset="1" stop-color="#5E438F"/>
      </radialGradient>
      <linearGradient id="paint3_linear_2994_8373" x1="40.3566" y1="25.3768" x2="35.2552" y2="32.6916"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#493474"/>
        <stop offset="1" stop-color="#8C66BA" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint4_radial_2994_8373" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(41.0552 26.504) rotate(-165.772) scale(24.9228 41.9552)">
        <stop offset="0.0584996" stop-color="#50E6FF"/>
        <stop offset="1" stop-color="#436DCD"/>
      </radialGradient>
      <linearGradient id="paint5_linear_2994_8373" x1="16.9758" y1="3.05655" x2="24.4868" y2="3.05655"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#2D3F80"/>
        <stop offset="1" stop-color="#436DCD" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint6_radial_2994_8373" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(41.0552 26.504) rotate(-165.772) scale(24.9228 41.9552)">
        <stop offset="0.0584996" stop-color="#50E6FF"/>
        <stop offset="1" stop-color="#436DCD"/>
      </radialGradient>
      <linearGradient id="paint7_linear_2994_8373" x1="16.9758" y1="3.05655" x2="24.4868" y2="3.05655"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#2D3F80"/>
        <stop offset="1" stop-color="#436DCD" stop-opacity="0"/>
      </linearGradient>
    </defs>
    <script xmlns="" id="bw-fido2-page-script"/>
  </svg>
}

function FreshBooks (params) {
  return <svg xmlns="http://www.w3.org/2000/svg" {...params} viewBox=".99522558 .9999996 253.69877442 253.6940004"
              width="2500">
    <path
        d="m107.948 1a106.948 106.948 0 0 0 -106.948 106.966v146.728h146.727c59.067 0 106.955-47.88 106.967-106.948v-146.746zm86.724 43.635a34.6 34.6 0 0 1 -10.164 24.51 34.768 34.768 0 0 1 -24.562 10.152h-37.242v29.73h50.314v34.796h-50.065v71.663h-41.233v-179.891h40.983v32.625c1.072-18.32 16.275-32.625 34.668-32.625h37.358z"
        fill="#0075dd"/>
    <script xmlns="" id="bw-fido2-page-script"/>
  </svg>
}

function ClientsSection(props) {
  const clients = [
    {
      name: "Microsoft 360",
      logo: Microsoft360,
    },
    {
      name: "Slack",
      logo: SlackIcon,
    },
    {
      name: "Jira",
      logo: JiraIcon,
    },
    {
      name: "Asana",
      logo: AsanaIcon,
    },
    {
      name: "Notion",
      logo: NotionIcon,
    },
    {
      name: "FreshBooks",
      logo: FreshBooks,
    },
  ];

  return (
      <Section
          size={props.size}
          bgColor={props.bgColor}
          bgImage={props.bgImage}
          bgImageOpacity={props.bgImageOpacity}
          textColor={props.textColor}
      >
        <div className="container">
          <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              strapline={props.strapline}
              className="text-center"
          />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-center text-blue-200">
            {clients.map((client, index) => (
                <div
                    className="h-28 flex items-center justify-center relative"
                    key={index}
                >
                  <div className="absolute inset-0 transform skew-x-6 bg-black bg-opacity-10"/>
                  <div className="relative inline-flex items-center space-x-2 text-2xl font-semibold">
                    <client.logo className="inline-block w-10 h-10"/>
                    <span>{client.name}</span>
                  </div>
                </div>
            ))}
          </div>
          <div className='text-xl text-center pt-10'>Use to the chat feature in the bottom right of the page to tell us what you want most</div>
        </div>
      </Section>
  );
}

export default ClientsSection;
