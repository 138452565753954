import React from "react";
import Meta from "./../components/Meta";
import AccountSection from "../components/AccountSection";
import { requireAuth } from "../util/auth";

function AccountPage() {
  return (
    <>
      <Meta title="Account" />
      <AccountSection
        title="Signed In"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
      />
    </>
  );
}

export default requireAuth(AccountPage);
