import React from "react";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import AccountPage from "./account";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import Chat from "./../components/Chat";
import { AuthProvider } from "../util/auth";
import { QueryClientProvider } from "../util/db";
import { Analytics } from '@vercel/analytics/react';
import {SignOut} from "./signout";
import {SignOutAllSessions} from "./signoutAllSessions";
import GetStartedPage from "./get-started";
import ExtensionPage from "./extension-sign-in";
import WorkingMothersCareerJumpStartResume from "./lead/working-mothers-career-jumpstart-resume";

function App() {
  return ( <>
        <Chat />
        <Router>
          <>
            <Navbar bgColor="bg-white" />

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/get-started" component={GetStartedPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/pricing" component={PricingPage} />

              <Route exact path="/account" component={AccountPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/signout" component={SignOut} />

              <Route exact path="/signout-all-sessions" component={SignOutAllSessions} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route exact path="/purchase/:plan" component={PurchasePage} />

              <Route exact path="/extension-sign-in" component={ExtensionPage} />

              <Route exact path="/working-mothers-career-jumpstart-resume" component={WorkingMothersCareerJumpStartResume} />

              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              size="md"
              bgColor="bg-white"
              bgImage=""
              bgImageOpacity={1}
              textColor=""
              sticky={true}
            />
          </>
        </Router>
  </>
  );
}

function AppPlusLanding() {
  return (
      <QueryClientProvider>
        <AuthProvider>
          <Router>
            <>
              <Switch>
                <Route exact path="/working-mothers-career-jumpstart-resume" component={WorkingMothersCareerJumpStartResume} />

                <Route component={App} />
              </Switch>
            </>
          </Router>
        </AuthProvider>
        <Analytics />
      </QueryClientProvider>
  );
}

export default AppPlusLanding;
