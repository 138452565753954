import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import {useRouter} from "../util/router";

function AuthPage() {
  const router = useRouter();

    let afterAuthPath = router.query.type === 'signup' ? '/pricing' : "/account";

    return (
    <>
      <Meta title="Auth" />
      <AuthSection
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        providers={["google"]}
        type={router.query.type}
        afterAuthPath={router.query.next || afterAuthPath}
      />
    </>
  );
}

export default AuthPage;
