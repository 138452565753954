import React from "react";
import {ArrowRightIcon} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import {Link} from "./../util/router";
import LogoIconTransparent from "./LogoIconTransparent.png";

function CtaSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-10 container">
        <div className="text-center">
          <div className="relative inline-flex w-20 h-20 items-center justify-center text-emerald-500 mb-10 mx-auto">
            <div className="relative">
              <img src={LogoIconTransparent} alt="All My Things Logo Icon"/>
            </div>
          </div>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
          <div className="text-center">
            <Button
              component={Link}
              to="/get-started"
              size="xl"
              variant={'new'}
              endIcon={
                <ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />
              }
              id='get-started-btn-home-page-free-account'
            >
              Let's Go
            </Button>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CtaSection;
