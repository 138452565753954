import React from "react";
import Section from "./Section";
import {Link} from "./../util/router";

function Footer(props) {

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={props.sticky && "mt-auto"}
    >
      <footer className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Product
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                { url: "/pricing", name: "Pricing" },
                { url: "/faq", name: "FAQ" },
              ].map((link, index) => {
                if(link.url.indexOf("//") !== -1) {
                  return (
                      <a
                          href={link.url}
                          className="font-medium text-gray-600 hover:text-gray-500"
                          key={index}
                      >
                        {link.name}
                      </a>
                  )
                }else{
                  return (
                      <Link
                          to={link.url}
                          className="font-medium text-gray-600 hover:text-gray-500"
                          key={index}
                      >
                        {link.name}
                      </Link>
                  )
                }})}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Company
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                { url: "/about", name: "About" },
                { url: "/legal/terms-of-service", name: "Terms of Service" },
                { url: "/legal/privacy-policy", name: "Privacy Policy" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-600 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div className="">
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Social
            </h4>
            <div className="mt-6 flex flex-row space-x-4">
              {[
                {
                  url: "https://www.linkedin.com/company/allmythings/",
                  icon: (
                      <svg className="inline-block w-5 h-5"
                           fill="currentColor"
                           enableBackground="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512">
    <g><path d="M1,53c2.5,0,1.7-2,1.9-3.3C5.9,29,16.6,13.9,36.6,7.4c16.1-5.2,32.9-4.6,47.9,4c9.4,5.5,16.6,14,20.4,24.5   c2.8,7.8,4.9,15.9,4,24.2c-2.1,20.9-11.5,38.3-34.5,46.4c-17.4,6-34.3,3.8-50.2-5.7c-1-3.6-3.5-5.4-7.1-6c0,0,0,0,0,0   c-0.1-1.4-0.3-2.8-2.2-2.8C7.7,84,4.3,74.3,2.9,63.8C2.7,62.6,3.2,60.9,1,61C1,58.3,1,55.7,1,53z"/>
      <path
          d="M276.2,216.1c4.3-2.6,6-6.4,8.2-9.5c10.9-15,24.4-26.6,41.2-34.9c23-11.3,47.4-12.5,72-10.2   c18.9,1.7,36.8,8.1,53.1,18.5c19.4,12.4,33.3,29.6,42.8,50.2c5.8,12.5,9.6,25.8,11.9,39.6c2.1,12.9,3.7,25.6,3.7,38.7   c-0.1,65-0.1,130,0.1,195c0,4.6-1.3,5.8-5.8,5.7c-30.7-0.2-61.3-0.2-92,0c-4.5,0-5.5-1.3-5.5-5.6c0.1-61.3,0.4-122.7-0.1-184   c-0.1-13.9-2-27.7-9.2-40.4c-6.4-11.2-15.3-19.2-27-23.8c-15.7-6.2-31.9-7.4-48.3-2.8c-16.6,4.6-28.1,15.5-36.3,30.3   c-4.6,8.3-6.2,17-7.5,26.3c-1.3,10-1.1,19.8-1.1,29.7c-0.2,54.7-0.2,109.3,0,164c0,5-1.2,6.4-6.3,6.3c-30.3-0.3-60.7-0.2-91,0   c-4.2,0-5.7-1.3-5.6-5.2c0.1-2.8,0.3-5.6,0.3-8.4c0-105.5,0.1-211-0.1-316.5c0-4.4,0.9-6,5.7-6c30.5,0.2,61,0.2,91.5,0   c4.1,0,5.5,1,5.4,5.3C276,190.6,276.2,202.9,276.2,216.1z"
          />
      <path
          d="M109,176.1c0,3.5,0.1,7,0.1,10.5c0,105.3,0,210.6,0,316c0,6.4,0,6.4-6.5,6.4c-31.8,0-63.7,0-95.5,0   c-0.1-2.5-0.2-5-0.2-7.5c0-78.8,0-157.5,0-236.3c0-28.1,0.1-56.3-0.1-84.4c0-3.6,0.9-4.9,4.7-4.9C44,176.1,76.5,176,109,176.1z"
          />
      <path
          d="M109,176.1c-32.5,0-64.9,0-97.4-0.2c-3.8,0-4.7,1.2-4.7,4.9C7,208.9,6.9,237,6.9,265.2   c0,78.8,0,157.5,0,236.3c0,2.5,0.1,5,0.2,7.5c-1.7,0.1-3-0.3-3-2.3c0-1.3,0-2.7,0-4c0-106.8,0-213.5,0-320.3c0-7.6,0.7-8.3,8.2-8.3   c30.5,0,61,0,91.4,0.1C105.5,174.1,108.2,172.7,109,176.1z"
          />
      <path d="M17.1,94.9c3.6,0.6,6.1,2.4,7.1,6C21.1,99.7,18.8,97.7,17.1,94.9z" />
      <path
          d="M14.9,92.1c1.9,0,2,1.4,2.2,2.8C15,95,15,93.6,14.9,92.1z" /></g>
</svg>
                  ),
                },
                {
                  url: "https://www.youtube.com/@AllMyThingsProTips",
                  icon: (
                      <svg className="inline-block w-5 h-5"
                           fill="currentColor" enableBackground="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512">
    <g><path d="M256.4,74.2c22.7,0,45.3-0.4,68,0.1c28.8,0.6,57.6,1.7,86.3,3c11.9,0.6,24,0.9,35.8,3.2   c28.1,5.4,44.8,22.7,52.5,50c4.1,14.5,5.6,29.4,6.9,44.3c3.5,40.1,2.8,80.2,1.8,120.3c-0.6,23.7-1.6,47.6-7.6,70.8   c-2.5,9.5-5.4,19-10.9,27.3c-10,15.1-24.4,23.7-42,26.9c-15.1,2.8-30.4,3.1-45.7,3.8c-28.3,1.3-56.6,1.9-84.9,2.6   c-33.5,0.9-67,0.7-100.4,0.4c-27.1-0.3-54.3-0.9-81.4-2c-22.9-0.9-45.9-1.3-68.5-5.7c-12.5-2.4-23.6-7.6-33.2-16.2   c-9-8.2-13.6-18.8-17-30c-7.3-24-7.8-48.8-9.3-73.6c-1.7-29-1.2-58-0.5-86.9c0.6-24.4,1.7-48.9,6.8-72.9c3-14.2,7.1-28.3,17.4-39.2   c12.7-13.4,28.9-19.8,47.2-21.1c21.9-1.5,43.9-2.5,65.8-3.4C181.1,74.3,218.8,73.8,256.4,74.2z M339.5,245.4   c-0.4-1.2-1.5-1.4-2.3-1.8c-23.5-12.4-47.1-24.8-70.7-37.2c-18.7-9.8-37.4-19.7-56.1-29.6c-5.2-2.7-5.3-2.6-5.3,3.4   c0,43.1,0,86.2,0,129.4c0,0.8,0,1.7,0,2.5c-0.1,2.6,0.9,3.4,3.3,2.1c2.9-1.6,5.8-3.2,8.8-4.8c39.4-20.4,78.9-40.9,118.3-61.3   C336.9,247.3,338.7,247,339.5,245.4z"/></g>
</svg>
                  ),
                },
                {
                  url: "https://www.facebook.com/allmythings.co/",
                  icon: (
                      <svg
                          className="inline-block w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                      >
                        <path
                            d="M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615V8z"/>
                      </svg>
                  ),
                },
                {
                  url: "https://www.instagram.com/all_my_things_extension/",
                  icon: (
                      <svg
                          className="inline-block w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                      >
                        <path
                            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8zm6.406-11.845a1.44 1.44 0 100 2.881 1.44 1.44 0 000-2.881z"/>
                      </svg>
                  ),
                },
              ].map((link, index) => (
                  <a
                      href={link.url}
                      className="text-gray-400 hover:text-blue-600"
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                  >
                      {link.icon}
                </a>
              ))}
            </div>
            <div className='hidden'>
              <h4 className="mt-10 text-sm uppercase font-semibold tracking-wider text-gray-400">
                Join Our Newsletter
              </h4>
            </div>
          </div>
        </div>
      </footer>
    </Section>
  );
}

export default Footer;
