import React, {useState, useEffect} from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {Link} from "./../util/router";
import {useAuth} from "../util/auth";
import {LockClosedIcon} from "@heroicons/react/24/solid";
import ClientsSection from "./ClientsSection";
import {isExtensionInstalled} from "../util/extension";

function AccountSection(props) {
  const [isInstalled, setIsInstalled] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    isExtensionInstalled().then(setIsInstalled)
  },[])

  const classes = {
    navLink:
        "font-semibold inline-flex items-center space-x-1 h-8 px-4 group-hover:text-blue-500 py-6",
    navLinkIcon:
        "opacity-50 transform transition duration-200 ease-out group-hover:rotate-180 inline-block w-4 h-4",
    dropdown: {
      base: "absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in opacity-0 group-hover:opacity-100",
      left: "right-0",
      center: "left-1/2 -translate-x-1/2",
      right: "left-0",
      normal: "w-48",
      large: "w-96",
      inner:
          "bg-white shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden",
      title:
          "text-xs uppercase font-semibold tracking-wider text-blue-800 mb-5",
      link: "text-gray-600 hover:text-blue-600 font-medium text-sm flex items-center space-x-2",
      icon: "opacity-25 inline-block w-5 h-5",
      feature:
          "p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm",
      featureName: "font-semibold mb-1",
      featureDescription: "opacity-75",
    },
    accountDropdown: {
      base: "absolute right-0 origin-top-right mt-2 w-48 z-10",
      inner:
          "bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded",
      link: "flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700",
      linkActive: "text-gray-700 bg-gray-100",
      linkInactive:
          "text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700",
      icon: "opacity-50 inline-block w-5 h-5",
    },
  };

  const actionLink = isInstalled ? {
    name: "Open a New Tab",
    href: 'chrome-extension://nifanddelhjglbhbjdeopnakhkcopiel/index.html',
  } :
      {
        name : "Install the Extension",
        href:  'https://chromewebstore.google.com/detail/all-my-things/nifanddelhjglbhbjdeopnakhkcopiel'
      }

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="flex flex-wrap">
          <div className="p-4 w-full md:w-1/2">
            <div className="p-6 rounded border border-gray-200 prose prose-a:text-blue-600 max-w-none">
              <h3 className="mb-4">Account Info</h3>
              <div>
                You are signed in as <strong>{auth.user.email}</strong>.
              </div>

              {auth.user.stripeSubscriptionId && (
                  <>
                    <div>
                      You are subscribed to the{" "}
                      <strong>{auth.user.planId} plan</strong>.
                    </div>
                    <div>
                      Your plan status is{" "}
                      <strong>{auth.user.stripeSubscriptionStatus}</strong>.
                    </div>
                  </>
              )}

              <div>
                You can change your account info{` `}
                {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                in{` `}
                <Link to="/settings/general">settings</Link>.
              </div>

              {!auth.user.stripeSubscriptionId && (
                  <div>
                    You can signup for a plan in{" "}
                    <Link to="/pricing">pricing</Link>.
                  </div>
              )}

              <Link
                  to="/signout-all-sessions"
                  className={`${classes.accountDropdown.link}`}
              >
                <LockClosedIcon
                    className={classes.accountDropdown.icon}
                />
                <span>Sign out of *all* Sessions</span>
              </Link>
            </div>
          </div>
          <div className="p-4 w-full md:w-1/2">
            <div className="rounded border border-gray-200">
              <h2 className='text-2xl md:text-3xl font-extrabold text-center p-10'>You are ready to go.<br/>
                <a href={actionLink.href} style={{color: 'blue'}}>{actionLink.name}</a>
              </h2>
            </div>
          </div>
        </div>
        <ClientsSection
            title="Future integrations prioritized by you"
            subtitle=""
            strapline=""
            size="md"
            bgColor="bg-blue-800"
            bgImage=""
            bgImageOpacity={1}
            textColor="text-white"
        />
      </div>
    </Section>
  );
}

export default AccountSection;
