
const extensionId = "nifanddelhjglbhbjdeopnakhkcopiel";

export const sendTokenToExtension = (token) => sendMessage({type: 'loginWithToken', token})
export const sendLogoutToExtension = () => sendMessage({type: 'logout'})

export const isExtensionInstalled = async () => {
    try {
        const response = await sendMessage({type: 'isInstalled'})

        return response === 'yes'
    }catch (error) {
        console.warn(error)

        return false
    }
}

export const numberOfAccounts = async () => {
    try {
        return await sendMessage({type: 'numberOfAccounts'})
    }catch (error) {
        console.warn(error)

        return 0
    }
}

export const openExtension = () => {
    try {
        return sendMessage({type: 'open'})
    }catch (error) {
        console.warn(error)

        return 0
    }
}


const sendMessage = async (message) => {
    if(!window || !window.chrome || !window.chrome.runtime){
        console.log("`chrome.runtime` is undefined. `chrome.runtime` is undefined in contexts where there is no connectable extension. The extension is most likely not installed yet. There is a small chance of a misconfiguration. Check manifest.json[externally_connectable][matches] for the extension you are trying to connect to")
        return
    }

    return new Promise((resolve, reject) => {
        window.chrome.runtime.sendMessage(extensionId, message, function (response) {
            if (window.chrome.runtime.lastError) {
                reject(window.chrome.runtime.lastError.message);
            } else {
                resolve(response)
            }
        });
    })
}