import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

/***
 gtag("event", "view_item_list", {
 item_list_id: "related_products",
 item_list_name: "Related products",
 items: [
 {
 item_id: "SKU_12345",
 item_name: "Stan and Friends Tee",
 affiliation: "Google Merchandise Store",
 coupon: "SUMMER_FUN",
 discount: 2.22,
 index: 0,
 item_brand: "Google",
 item_category: "Apparel",
 item_category2: "Adult",
 item_category3: "Shirts",
 item_category4: "Crew",
 item_category5: "Short sleeve",
 item_list_id: "related_products",
 item_list_name: "Related Products",
 item_variant: "green",
 location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
 price: 10.01,
 quantity: 3
 }
 ]
 });
     **/
function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title=""
        subtitle=""
        strapline="Pricing"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingPage;
