import {history} from "../util/router";
import {useAuth} from "../util/auth";
import {useEffect} from "react";

export const SignOut = () => {
    const auth = useAuth();

    useEffect(() => {
        auth.signout();
        history.replace("/");
    }, [auth]);

    return null
}