import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";

function AboutPage() {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection
        title={
          <>
            We are <span className="font-light">The Carrot Farm 🥕</span>
          </>
        }
        subtitle="A passionate team who believes people do better running toward rewards instead of away from punishments."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        leftImage="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?crop=entropy&fit=crop&h=800&w=1280"
        rightImage="https://images.unsplash.com/photo-1554232456-8727aae0cfa4?crop=entropy&fit=crop&h=800&q=80&w=600"
      />
      <StatsSection
        title="Decades of experience"
        subtitle="From Ad Agencies to startups to Fortune 500 companies"
        strapline="Technology Dedication"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <TeamBiosSection
        title="Meet our amazing team"
        subtitle="It is basically Dominic Scimeca"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <CtaSection
        title={
          <>
            Create an account <span className="text-blue-600">today</span>!
          </>
        }
        strapline="Quickly navigate all your business and personal accounts at once!"
        subtitle=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
