import TextField from "./TextField";
import Button from "./Button";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import newsletter from "../util/newsletter";
import clsx from "clsx";
import LoadingIcon from "./LoadingIcon";

export const MailChimpSignUp = () => {
    const [subscribed, setSubscribed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasError, setSetHasError] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = ({ email }) => {
        setLoading(true)
        newsletter.subscribe({ email })
            .then(() => {
                setSubscribed(true)
                setLoading(false)
            })
            .catch((err) => {
                setSetHasError(true)
                setLoading(false)
            });
    };

    return (
        <div>
            <h4 className="mt-10 text-sm uppercase font-semibold tracking-wider text-gray-400">
                Join Our Newsletter
            </h4>

            {subscribed === true && (
                <div className="mt-3">You are now subscribed!</div>
            )}

            {subscribed === false && (
                <form
                    className="mt-6 flex items-start space-x-2"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="grow">
                        {loading && <LoadingIcon className="w-6"/>}
                        {/*<TextField*/}
                        {/*    type="email"*/}
                        {/*    placeholder="Email"*/}
                        {/*    {...register(*/}
                        {/*        'email',*/}
                        {/*        {*/}
                        {/*            required: true,*/}
                        {/*        }*/}
                        {/*    )}*/}
                        {/*    size="sm"*/}
                        {/*    className={clsx(hasError && 'border-red-400 border-2')}*/}
                        {/*/>*/}
                        <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            {...register(
                                'email',
                                {
                                    required: true,
                                }
                            )}
                            size="sm"
                            className={clsx(hasError && 'border-red-400 border-2')}
                        />
                        {errors.email && <p>Please enter a valid email address.</p>}
                    </div>
                    <Button type="submit" size="sm">
                        Subscribe
                    </Button>
                </form>
            )}
        </div>
    )
}