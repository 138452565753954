import clsx from 'clsx'

import Button from './Button'
import {Container} from './Container'
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {useState} from "react";
import {Link} from "../util/router";
import {ArrowTrendingDownIcon} from "@heroicons/react/24/outline";

function SwirlyDoodle(props) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 281 40"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z"
      />
    </svg>
  )
}

function Plan({ name, price, description, href, features, featured = false }) {
  return (
    <section
      className={clsx(
        'flex flex-col rounded-3xl px-6 sm:px-8',
        featured ? 'order-first bg-blue-600 py-8 lg:order-none' : 'lg:py-8',
      )}
    >
      <h3 className="mt-5 font-display text-lg text-white">{name}</h3>

      <p className="order-first font-display text-5xl font-light tracking-tight text-white">
        {price}
      </p>

        <ul
            role="list"
            className={clsx(
                'order-last mt-10 flex flex-col gap-y-3 text-sm',
                featured ? 'text-white' : 'text-slate-200',
            )}
        >
            {features.map((feature) => (
                <li key={feature} className="flex">
                    <ArrowTrendingDownIcon className={clsx(
                        'w-6 h-6',
                        featured ? 'text-white' : 'text-slate-400'
                    )}/>
                    <span className="ml-4">{feature}</span>
                </li>
            ))}

            <p
                className={clsx(
                    'mt-2 text-base',
                    featured ? 'text-white' : 'text-slate-400',
                )}
            >
                {description}
            </p>
            <Button
                href={href}
                variant={featured ? 'solid' : 'outline'}
                color="white"
                className="mt-8 w-auto border"
                id='get-started-btn-value-to-customer'
                component={Link}
                to="/get-started"
                aria-label={`Get started for free`}
            >
                Try it out for free
            </Button>
        </ul>

    </section>
  )
}

const workHoursInAMonth = 8 * 5 * 4
const allMyThingSolutionPercentage = .1
const lowerProductivityPercentageLost = .1
const higherProductivityPercentageLost = .48
const maxFunctioningAccounts = 10
const maxTimeLost = 31

export function ValueToCustomer() {
    const [timeWorth, setTimeWorth] = useState(50)
    const [numOfAccounts, setNumOfAccounts] = useState(3)
    const [avgTimeLost, setAvgTimeLost] = useState(10)
    const [showToolTip, setShowToolTip] = useState(false)

    const savingsPerMonth = Math.round(
        (timeWorth * workHoursInAMonth) *
        (lowerProductivityPercentageLost + ((numOfAccounts/maxFunctioningAccounts + avgTimeLost/maxTimeLost)/2) * (higherProductivityPercentageLost - lowerProductivityPercentageLost)) *
        allMyThingSolutionPercentage
    )

    return (
        <section
            id="value-to-customer"
            aria-label="ValueToCustomer"
            className="bg-slate-900 py-20 sm:py-32"
        >
            <Container>
                <div className="md:text-center">
                    <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            <span className="relative whitespace-nowrap">
              <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-400"/>
              <span className="relative">How much time</span>
            </span>{' '}
                        could you reclaim as a working mother?
                    </h2>
                    <p className="mt-4 text-lg text-slate-400 relative">
                        Calculate how much time you could save on managing emails, calendars, and tasks – and spend it with your family instead. <InformationCircleIcon className='w-6 inline'
                                                                                                 onMouseEnter={() => setShowToolTip(true)}
                                                                                                 onMouseLeave={() => setShowToolTip(false)}
                    />
                        <span
                            className={clsx(
                                'items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600 absolute top-full',
                                showToolTip ? 'block' : 'hidden',
                            )}>
                            8 hours * 5 days a week * 4 weeks a month * your value per hour * (.1 [lower productivity loss bounds] + (num of accounts / 10 [max accounts you can productively juggle] + avg time lost / 31 [max avg time lost] ) / 2 ) * .38 [higher productivity loss bounds] * .1 [Percentage of Productivity loss All My Things can reclaim]
                        </span>
                    </p>
                </div>
                <div
                    className="-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-2 xl:mx-0 xl:gap-x-8 gap-x-80">
                    <form className=''>
                        <div className="space-y-12">
                        <div className="border-b border-white/10 pb-12">
                                <div className='flex'>
                                    <div>
                                        <h2 className=" font-semibold leading-7 text-white text-3xl">20%</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-400">
                                            of an employee's time is spent looking for information
                                        </p>
                                        <p className="mt-1 text-xs leading-6 text-gray-400 italic">
                                            Source: McKinsey
                                        </p>
                                    </div>
                                    <div>
                                        <h2 className="text-3xl font-semibold leading-7 text-white">38%</h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-400">
                                            of time is spent unsuccessfully searching and re-creating content
                                        </p>
                                        <p className="mt-1 text-xs leading-6 text-gray-400 italic">
                                            Source: IDC
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="username"
                                               className="block text-sm font-medium leading-6 text-white">
                                            How much is your time worth per hour?
                                        </label>
                                        <div className="mt-2">
                                            <div
                                                className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                                    <span
                                        className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">$</span>
                                                <input
                                                    type="number"
                                                    name="time-worth"
                                                    id="time-worth"
                                                    value={timeWorth}
                                                    onChange={e => setTimeWorth(e.target.value)}
                                                    className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder="50?"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="username"
                                               className="block text-sm font-medium leading-6 text-white">
                                            How many business + personal google accounts do you use a month?
                                        </label>
                                        <div className="mt-2">
                                            <div
                                                className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 pl-3">

                                                <input
                                                    type="number"
                                                    max='10'
                                                    name="num-of-accounts"
                                                    id="num-of-accounts"
                                                    value={numOfAccounts}
                                                    onChange={e => setNumOfAccounts(e.target.value)}
                                                    className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="username"
                                               className="block text-sm font-medium leading-6 text-white">
                                            How long does it take you to find lost things on Google Workspace? [gmail, drive, calendar combined]
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="average-time-lost"
                                                name="average-time-lost"
                                                value={avgTimeLost}
                                                onChange={e => setAvgTimeLost(e.target.value)}
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                            >
                                                <option value={2}>1 - 2 minutes</option>
                                                <option value={15}>3 - 15 minutes</option>
                                                <option value={30}>15 - 30 minutes</option>
                                                <option value={maxTimeLost}>31+ minutes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Plan
                        featured
                        name="is lost per month"
                        price={`${savingsPerMonth} hours`}
                        description="What could be the value of reclaiming that time?"
                        features={[
                            'Logging in and out...',
                            'Searching across Drive, Docs, Gmail, Calendar...',
                            'Finding what account something is on...',
                            'Missing emails and important calendar invites...',
                            'Organizing your Google drive...',
                        ]}
                    />
                </div>
            </Container>
        </section>
    )
}
