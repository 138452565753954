import React, {Fragment, useEffect, useState} from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import {useAuth} from "../util/auth";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import analytics, {analyticsTrackingIds} from "../util/analytics";
import {isExtensionInstalled, numberOfAccounts} from "../util/extension";
import {Transition} from "@headlessui/react";
import SectionHeader from "../components/SectionHeader";
import ChromeWebstoreIcon from '../components/chromestoreicon.svg'
import clsx from "clsx";

const SignUpCouponCode = 'ASETUPWELLDONE'

function GetStartedPage() {
  const [isInstalled, setIsInstalled] = useState(false);
  const [numOfAccounts, setNumOfAccounts] = useState(0);
  const [copiedCode, setCopiedCode] = useState(false);

  const auth = useAuth();
  const isSignedIn = !!auth.user

  useEffect(() => {
      const setCompletionInformation = () => {
          isExtensionInstalled()
              .then(response => {
                  const alreadyTriggeredInstalled = localStorage.getItem('hasTriggeredInstallExtensionConfirmation')

                  if(response && alreadyTriggeredInstalled !== 'true') {
                      localStorage.setItem('hasTriggeredInstallExtensionConfirmation', 'true')

                      analytics
                          .track('extensionInstalled')
                          .then(() => {
                              setIsInstalled(response)
                          })

                  }else{
                      setIsInstalled(response)
                  }
              })

          numberOfAccounts().then(response => {
              const hasTriggeredAccounts1Added = localStorage.getItem('hasTriggeredAccounts1Added')
              const hasTriggeredAccounts2Added = localStorage.getItem('hasTriggeredAccounts2Added')

              if(response && response === 1 && hasTriggeredAccounts1Added !== 'true') {
                  localStorage.setItem('hasTriggeredAccounts1Added', 'true')

                  analytics
                      .track('accounts1Added')
                      .then(() => {
                          setNumOfAccounts(response)
                      })

              }else if(response && response >= 2 && hasTriggeredAccounts2Added !== 'true') {
                  localStorage.setItem('hasTriggeredAccounts2Added', 'true')

                  analytics
                      .track('accounts2Added')
                      .then(() => {
                          setNumOfAccounts(response)
                      })

              }else{
                  setNumOfAccounts(response)
              }
          })
      }

      setCompletionInformation()

      const intervalId = setInterval(() => {
          setCompletionInformation()
      }, 2000)

      return () => {
          clearInterval(intervalId)
      }
  },[])

      const SignUpComponent = ({isShowing}) => {
      return (<>
              <Transition
                  show={isShowing}
                  as="div"
                  enter="transform transition duration-[400ms]"
                  enterFrom="opacity-0 rotate-[-120deg] scale-50"
                  enterTo="opacity-100 rotate-0 scale-100"
                  leave="transform duration-200 transition ease-in-out"
                  leaveFrom="opacity-100 rotate-0 scale-100 "
                  leaveTo="opacity-0 scale-95 "
              >
                  <AuthSection
                      size="md"
                      bgColor=""
                      bgImage=""
                      className={clsx([
                          // Shared closed styles
                          'data-[closed]:opacity-0 data-[closed]:scale-95',
                          // Entering styles
                          'data-[enter]:duration-100 data-[enter]:data-[closed]:-translate-x-full',
                          // Leaving styles
                          'data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full',
                      ])}
                      bgImageOpacity={1}
                      textColor="blue"
                      type={'signup'}
                      providers={["google"]}
                      options={{
                          title: '',
                      }}
                      onAuth={() => {
                          analytics.track('signedUp')
                      }}
                  />
              </Transition>
          </>)
      }

      const CheckedOffComponent = ({isShowing, ...props}) => {
          return (<>
                <Transition
                    appear={true}
                    show={isShowing}
                    enter="transform transition duration-[400ms]"
                    enterFrom="opacity-0 rotate-[-120deg] scale-50"
                    enterTo="opacity-100 rotate-0 scale-100"
                    leave="transform duration-200 transition ease-in-out"
                    leaveFrom="opacity-100 rotate-0 scale-100 "
                    leaveTo="opacity-0 scale-95 "
                >
                    <CheckCircleIcon {...props} className='w-56 text-green-50 pb-10'/>
                </Transition>
            </>)
      }

      const Stage = ({children, header, enabled, completed, className, ...props}) => {
        return (
            <div {...props} className={`bg-white bg-opacity-10 rounded-xl px-8 py-12 md:px-12 lg:px-12 relative text-white ${ !enabled ? 'opacity-50' : ''} flex-grow ${className}`}>
                { !enabled && <div className='absolute h-full w-full bg-white bg-opacity-40 top-0 left-0 rounded-xl z-10'></div> }
                <SectionHeader
                    title={header}
                    subtitle=""
                    strapline=""
                    className="text-center"
                />
                <div className='flex flex-col items-center justify-center text-center'>
                    {children}
                </div>
            </div>
        )
      }

    const stageInfo = {}
    stageInfo['one'] = {
      enabled: true,
      completed: isSignedIn,
    }
    stageInfo['two'] = {
      enabled: stageInfo['one'].completed,
      completed: stageInfo['one'].completed && isInstalled,
    }
    stageInfo['three'] = {
        enabled: stageInfo['two'].completed,
        completed: stageInfo['two'].completed && numOfAccounts >= 2,
    }
    stageInfo['four'] = {
        enabled: stageInfo['three'].completed,
        completed: stageInfo['three'].completed && copiedCode,
    }

      const StageOne = ({enabled, completed}) => {
        return <Stage header='Register' enabled={enabled} completed={completed}>
            <SignUpComponent isShowing={!completed}/>
            <CheckedOffComponent isShowing={completed} />
        </Stage>
      }

    const StageTwo = ({enabled, completed}) => {
      return <Stage header='Install' enabled={enabled} completed={completed}>
            <CheckedOffComponent isShowing={completed} />
            { enabled && !completed &&
                <a target='_blank' href='https://chromewebstore.google.com/detail/all-my-things/nifanddelhjglbhbjdeopnakhkcopiel?referred-from-website=true'>
                    <img src={ChromeWebstoreIcon} alt='Chrome Webstore Icon'/>
                </a>
            }
        </Stage>
    }

    const StageFourDiscountCode = () => {
      return (<div className='text-2xl'>

          <p className='py-3'>As a token of our appreciation: We offer you now</p>
          <p></p>
          <h3 className='text-4xl'>2 months free!</h3>
          <p className='mt-10'>

              <span className='text-md'>Code:</span> <span
              className='text-2xl p-3 font-semibold focus:outline-none rounded focus:ring disabled:opacity-50 inline-block'>
              {SignUpCouponCode}
          </span>
              <button onClick={() => {
                  analytics
                      .track('copiedSignUpCode')
                      .then(() => {
                          navigator.clipboard.writeText(SignUpCouponCode);
                          setCopiedCode(true)
                      })
              }}>
                  {!copiedCode &&
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                           stroke="currentColor" className="w-[58px] inline-block border rounded p-3">
                          <path strokeLinecap="round" strokeLinejoin="round"
                                d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"/>
                      </svg>
                  }
                  {copiedCode &&
                      <><span className='text-sm italic'>Copied!</span> <CheckCircleIcon className='w-6 text-green-50 inline'/></>
                  }
              </button>
          </p>
          <p className='my-5'>
              <a className="group mr-5 inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white"
                 color="white" variant="solid" href="/pricing">
                  Go Pro
              </a>
          </p>
          <h6 className='text-sm italic'>Pay nothing now. Cancel anytime.</h6>
      </div>)
    }

    const StageThree = ({enabled, completed}) => {
        return <Stage header='Add Accounts' enabled={enabled} completed={completed}>
            {enabled && !completed && <span>
                <ul>
                    <li>Finish your setup.</li>
                    <li><a href='chrome-extension://nifanddelhjglbhbjdeopnakhkcopiel/index.html'>Add</a> two Google accounts.</li>
                </ul>

                Right now you have {numOfAccounts} accounts.
            </span>}

            <CheckedOffComponent isShowing={completed} />
        </Stage>
    }

    const StageFour = ({enabled, completed}) => {
        return <Stage header='Congratulations! A great setup. Well done!' enabled={enabled} completed={completed}>
            {enabled && <StageFourDiscountCode/>}
        </Stage>
    }


    return (
        <>
            <Meta title="Get Started"/>
            <div className='p-5 py-20 pb-56 bg-cover'
                 style={{backgroundImage: 'url("/images/background-features.jpg")'}}
            >
                <div className="container text-center">
                    <h1
                        className="text-5xl md:text-6xl lg:text-7xl font-semibold font-heading leading-tight text-white">Hello
                        Simplicity!</h1>
                    <p className="text-xl md:text-2xl lg:text-3xl leading-tight my-6 text-blue-200">Sign up for an
                        always free account. No credit card is needed.
                        If you need more accounts or more features, upgrading is easy and you can cancel your
                        subscription at any time</p>
                </div>
                <div className='text-white container hidden'>
                    <h4 className="sr-only">Status</h4>
                    <p className="text-sm font-medium ">Get <b>21</b> days of free Premium</p>
                    <div className="mt-6" aria-hidden="true">
                        <div className="overflow-hidden rounded-full bg-gray-200">
                            <div className="h-2 rounded-full bg-green-600" style={{width: '37.5%'}}/>
                        </div>
                        <div className="mt-6 hidden grid-cols-3 text-sm font-medium sm:grid">
                            <div className="text-green-600">Create account</div>
                            <div className="text-center text-green-600">Install Extension</div>
                            <div className="text-center">Add 2 Google Accounts</div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-4 p-5 container just'>
                    <StageOne {...stageInfo['one']}/>
                    <StageTwo  {...stageInfo['two']}/>
                    <StageThree {...stageInfo['three']}/>
                </div>
                <div className='flex gap-4 p-5 container just'>
                    <StageFour {...stageInfo['four']}/>
                </div>
            </div>
        </>
    );
}

export default GetStartedPage;
