import {signOutAllSessions} from "../util/auth";
import {useEffect, useState} from "react";
import {SignOut} from "./signout";

export const SignOutAllSessions = () => {
    const [runLocalSignOut, setRunLocalSignOut] = useState(false)

    useEffect(() => {
        signOutAllSessions()
            .then(() =>
                setRunLocalSignOut(true)
            )
    }, []);

    if(runLocalSignOut)
        return <SignOut/>

    return null
}